export const environment = {
  production: false,
  test: true,
  development: false,
  serviceworker: false,
  api: 'https://test-promocjeb2c.amica.pl/api/',
  oldApi: 'https://api-test-moja.amica.pl/api/',
  uploadFilesLocation: 'https://test-promocjeb2c.amica.pl/',
  amcUrl: 'https://test-moja.amica.pl',
  confirmationLink: 'https://test-moja.amica.pl/potwierdzenie-konta/',
  bearerToken: '4qjesnmvq54w804woo0wkkcokg0k8kk0owko4480co8g4c084019kzkotkf22sg8oowcgssw088kkw8wckgoggos4404kowss888',
  gtm: 'GTM-KJJG3V',
  googleMapsKey: 'AIzaSyDhZef0kwLhTP0KGxExOpHgKx8hqQV1Otw',
  recaptchaV3SiteKey: '6LeR9oIaAAAAAH6KnebO9Ko2KPcfC4UYt3PNeABB',
  syneriseKey: 'a815559a-6053-4818-8a31-a4d185935f36',
  facebookPixelId: '2116406188590840',
  ssoUrl: 'https://test-sso.amica.pl',
  ssoClientId: 'f5235ecac2e6259f2e455c1ae6970155',
  ssoAmicaClientId: 'e73237f3bd7af472c95cf88b62ae1c26',
  ssoAmicaClientUrl: 'https://www.amica.pl',
  promoFiveYearsId: 61,
  promoTimeForSteam: 62,
  pollTimeForSteamId: 29,
  promoVacuumUpToAPairId: 64,
  promoSetsComesInPairsId: 65,
  pollSetsComesInPairsId: 30,
  promoEveryKitchenIsARewardId: 66,
  pollEveryKitchenIsARewardId: 31,
  promoMyOpinionId: 67,
  pollMyOpinionId: 32,
  promoBlikId: 68,
  pollBlikId: 33,
  promoSatisfactionId: 69,
  promoAddColorId: 70,
  opinionWorthBlikId: 71,
  promoBuyRateGainId: 72,
  pollBuyRateGainId: 34,
};
