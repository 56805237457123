import {
  IFileInputResult,
  IInvoice,
  IOtherDocument,
  IProductDataForDisplay,
  IProductFormModel,
  IWarrantyCard,
} from '@am-canteens/data';

export interface IAddedProduct {
  invoice: IInvoice;
  warrantyCard: IWarrantyCard;
  otherDocument: IOtherDocument | null;
  productDataForDisplay: IProductDataForDisplay;
  productFormModel: IProductFormModel;
  invoiceFile: IFileInputResult[];
  warrantyCardFile: IFileInputResult[];
  otherDocumentFile: IFileInputResult[] | null;
}

export function isAddedProduct(arg: any): arg is IAddedProduct {
  return (
    arg &&
    arg.invoice &&
    arg.warrantyCard &&
    arg.otherDocument &&
    arg.productDataForDisplay &&
    arg.productFormModel &&
    arg.invoiceFile &&
    arg.warrantyCardFile &&
    arg.otherDocumentFile
  );
}
